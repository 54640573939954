import Link from "next/link";
import { useState, useEffect } from "react";
import { useRouter } from "next/router";
import Image from "next/image";
// Import the translation hook
import useTranslation from "next-translate/useTranslation";

function Header({ headerClass = null }) {
    const [menu, setMenu] = useState(false);
    const [langOpen, setLangOpen] = useState(false); // For language dropdown
    const [categories, setCategories] = useState([]); // Add this state
    const [showAnnouncement, setShowAnnouncement] = useState(false); // State to control announcement visibility
    const [isAnnouncementFading, setIsAnnouncementFading] = useState(false); // State to control fade animation

    const router = useRouter();
    const { locales, locale, asPath } = router;

    // For multilingual text from common.json
    const { t } = useTranslation("common");

    useEffect(() => {
        // Timer to start fade animation after 5 seconds
        const fadeTimer = setTimeout(() => {
            setIsAnnouncementFading(true);

            // Hide component after animation completes
            const hideTimer = setTimeout(() => {
                setShowAnnouncement(false);
            }, 500); // Animation duration

            return () => clearTimeout(hideTimer);
        }, 10000);

        // Fetch WordPress categories when component mounts
        // fetch('https://blog.instaswap.com/wp-json/wp/v2/categories')
        //     .then(response => response.json())
        //     .then(data => {
        //         // Filter out the "uncategorized" category and store the rest
        //         const filteredCategories = data.filter(cat =>
        //             cat.slug !== 'uncategorized' && cat.count > 0
        //         );
        //         setCategories(filteredCategories);
        //     })
        //     .catch(error => console.error('Error fetching categories:', error));

        // Existing useEffect code
        window.addEventListener("scroll", isSticky);
        router.events.on("routeChangeStart", removeActive);

        // Cleanup timer on component unmount
        return () => {
            clearTimeout(fadeTimer);
            window.removeEventListener("scroll", isSticky);
            router.events.off("routeChangeStart", removeActive);
        };
    }, []);

    const isSticky = () => {
        const header = document.querySelector(".header-section");
        const scrollTop = window.scrollY;
        if (scrollTop >= 250) {
            header?.classList.add("header-fixed", "fadeInUp");
        } else {
            header?.classList.remove("header-fixed", "fadeInUp");
        }
    };

    function removeActive() {
        // This can be used to close menus on route changes
        setMenu(false);
        setLangOpen(false);
    }

    function closeAllMenus() {
        let elements = document.querySelectorAll(".menu-item-has-children.open");
        elements.forEach((item) => {
            item.classList.remove("open");
            const submenu = item.querySelector(".submenu");
            if (submenu) submenu.style.display = "none";
        });
    }

    const toggleMenu = () => {
        const newValue = !menu;
        setMenu(newValue);

        if (newValue) {
            // On mobile, expand sub menus
            const mediaQuery = window.matchMedia("(max-width: 991px)");
            if (mediaQuery.matches) {
                let elements = document.querySelectorAll(".menu-item-has-children");
                elements.forEach((item) => {
                    item.classList.add("open");
                    const submenu = item.querySelector(".submenu");
                    if (submenu) submenu.style.display = "block";
                });
            }
        } else {
            closeAllMenus();
        }
    };

    function toggleActive(event) {
        event.preventDefault();
        const mediaQuery = window.matchMedia("(max-width: 991px)");
        if (mediaQuery.matches) {
            const parentLi = event.currentTarget.parentElement;
            parentLi.classList.toggle("open");
            const submenu = parentLi.querySelector(".submenu");
            if (submenu) {
                if (!submenu.style.display || submenu.style.display === "none") {
                    submenu.style.display = "block";
                } else {
                    submenu.style.display = "none";
                }
            }
        }
    }

    // Toggle the language dropdown
    const toggleLangMenu = () => {
        setLangOpen(!langOpen);
    };

    // On link click, close the dropdown
    const handleLangSelect = () => {
        setLangOpen(false);
        setMenu(false);
    };

    return (
        <>
            <header
                className={`header-section ${headerClass ? headerClass : "bg-color-3"}`}
            >
                {showAnnouncement && (
                    <div className={`announcement-bar ${isAnnouncementFading ? 'fade-up-out' : ''}`} style={{
                        backgroundColor: "rgb(26, 49, 48)",
                        padding: "0px",
                        position: "absolute",
                        width: "100%",
                        zIndex: 1,
                        marginTop: "-2px",
                        height: "25px",
                        color: "#939393",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        textAlign: "center",
                    }}>
                        <div className="announcement-content" style={{
                            display: "inline-block",
                            paddingLeft: "5px",
                        }}>
                            <strong>{t('menu.bewareOfScammersTitle')}</strong> {t('menu.bewareOfScammersText')}
                        </div>
                    </div>
                )}
                {/* <style jsx>{`
                    @keyframes marquee {
                        0% { transform: translateX(0); }
                        100% { transform: translateX(-100%); }
                    }
                    
                    @keyframes fadeUpOut {
                        0% { 
                            opacity: 1;
                            transform: translateY(0);
                        }
                        100% { 
                            opacity: 0;
                            transform: translateY(-100%);
                        }
                    }
                    
                    .fade-up-out {
                        animation: fadeUpOut 0.5s ease forwards;
                    }
                    
                    @media (max-width: 767px) {
                        .announcement-content {
                            animation: marquee 15s linear infinite;
                            padding-left: 100% !important;
                        }
                        
                        .announcement-content:hover {
                            animation-play-state: paused;
                        }
                    }
                `}</style> */}

                <div className="header-bottom">
                    <div className="container" style={{ maxWidth: "100%" }}>
                        <div className="header-wrapper">
                            {/* LOGO */}
                            <div className="logo">
                                <Link href="/" prefetch={false}>
                                    <Image
                                        className="dark"
                                        src="/images/logo/instaswapFullLogo.svg"
                                        alt="logo"
                                        width={250}
                                        height={50}
                                    />
                                </Link>
                            </div>

                            {/* MAIN MENU */}
                            <div className="menu-area">
                                {/* Updated backdrop style with background-color transition */}
                                <div id="menuBackdrop" style={{
                                    opacity: menu ? 1 : 0,
                                    pointerEvents: menu ? "auto" : "none",
                                    width: "100%",
                                    height: "100vh",
                                    position: "absolute",
                                    top: "0px",
                                    left: "0px",
                                    zIndex: -1,
                                    transition: "opacity 0.3s ease, background-color 0.5s ease",
                                    backgroundColor: "rgb(18 26 39 / 96%)"
                                }}>
                                    <div className="col-md-12" style={{
                                        bottom: 0,
                                        position: "absolute",
                                        width: "100%",
                                        left: 0,
                                        padding: "20px",
                                    }}>
                                        <div
                                            className="footer__app"
                                            style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                justifyContent: "flex-start",
                                                alignItems: "center",
                                                width: "100%",
                                                flexWrap: "nowrap",
                                                alignContent: "center",
                                            }}
                                        >
                                            <div className="footer__app-item footer__app-item--apple" style={{ marginRight: "20px", width: "100%" }}>
                                                <div className="footer__app-inner">
                                                    <div className="footer__app-thumb">
                                                        <Link href="mailto:support@instaswap.com" className="stretched-link" aria-label="Contact support via email">
                                                            <Image src="/images/icon/mail-dark.svg" alt="Mail icon" width={32} height={32} loading="lazy" />
                                                        </Link>
                                                    </div>
                                                    <div className="footer__app-content">
                                                        <span>Constact us on</span>
                                                        <p className="mb-0">support@instaswap.com</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="footer__app-item footer__app-item--playstore footer__app-item--playstore3">
                                                <div className="footer__app-inner">
                                                    <div className="footer__app-thumb">
                                                        <Link href="https://discord.gg/um6NazJSMk" target="_blank" className="stretched-link" aria-label="Join our Discord Community">
                                                            <Image src="/images/icon/discord-dark.svg" alt="Discord icon" width={32} height={32} loading="lazy" />
                                                        </Link>
                                                    </div>
                                                    <div className="footer__app-content">
                                                        <span>Find us on</span>
                                                        <p className="mb-0">Discord Community</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <ul
                                    id="menu"
                                    className={`menu menu--style1 ${menu ? "active" : ""}`}
                                >
                                    {/* homepage */}
                                    <li>
                                        <Link href="/" prefetch={false}>{t("menu.home")}</Link>
                                    </li>
                                    {/* How It Works */}
                                    <li className="menu-item-has-children">
                                        <a href="#" onClick={(e) => { e.preventDefault(); toggleActive(e); }}>
                                            {t("menu.howItWorks")}
                                        </a>
                                        <ul className="submenu">
                                            <li>
                                                <Link href="/howswapswork" prefetch={false}>{t("menu.defiSwaps")}</Link>
                                            </li>
                                            <li>
                                                <Link href="/walletless" prefetch={false}>
                                                    {t("menu.defiWalletlessSwaps")}
                                                </Link>
                                            </li>
                                        </ul>
                                    </li>

                                    {/* Business */}
                                    <li className="menu-item-has-children">
                                        <Link href="#" onClick={(e) => { e.preventDefault(); toggleActive(e); }}>
                                            {t("menu.business")}
                                        </Link>
                                        <ul className="submenu">
                                            <li>
                                                <Link href="/for-business" prefetch={false}>
                                                    {t("menu.becomePartner")}
                                                </Link>
                                            </li>
                                            <li>
                                                <Link href="/api-integration" prefetch={false}>
                                                    {t("menu.apiIntegration")}
                                                </Link>
                                            </li>
                                            <li>
                                                <Link href="/swap-widget" prefetch={false}>
                                                    {t("menu.swapWidget")}
                                                </Link>
                                            </li>
                                            <li>
                                                <Link href="/partners-portal" prefetch={false}>
                                                    {t("menu.partnersPortal")}
                                                </Link>
                                            </li>
                                            {/* <li>
                                                <Link href="/wordpress-widget">
                                                    {t("menu.wordpressWidget")}
                                                </Link>
                                            </li> */}
                                        </ul>
                                    </li>

                                    {/* Affiliate */}
                                    <li>
                                        <Link href="/affiliate" prefetch={false}>{t("menu.affiliate")}</Link>
                                    </li>
                                    {/* Blog */}
                                    {/* <li className="menu-item-has-children">
                                        <Link href="#" onClick={(e) => { e.preventDefault(); toggleActive(e); }}>
                                            {t("menu.news")}
                                        </Link>
                                        <ul className="submenu">
                                            <li>
                                                <Link href="https://blog.instaswap.com/" target="_blank">
                                                    {t("menu.latestNews")}
                                                </Link>
                                            </li>
                                            {categories.map(category => (
                                                <li key={category.id}>
                                                    <Link
                                                        href={`https://blog.instaswap.com/category/${category.slug}/`}
                                                        target="_blank"
                                                    >
                                                        {category.name}
                                                    </Link>
                                                </li>
                                            ))}
                                        </ul>
                                    </li> */}
                                    {/* <li className="menu-item-has-children">

                                        <Link href="#" onClick={(e) => { e.preventDefault(); toggleActive(e); }}>
                                            {t("menu.announcements")}
                                        </Link>
                                        <ul className="submenu">
                                            <li>
                                                <Link href="https://blog.instaswap.com/the-fundamentals-of-decentralized-finance-defi-a-comprehensive-guide/" target="_blank">
                                                    {t("menu.defiGuide")}
                                                </Link>
                                            </li>
                                            <li>
                                                <Link href="https://blog.instaswap.com/easy-guide-to-native-crypto-cross-chain-swaps-via-the-instaswap-app/" target="_blank">
                                                    {t("menu.insGuid")}
                                                </Link>
                                            </li>
                                        </ul>
                                    </li> */}
                                </ul>
                            </div>

                            {/* HEADER ACTION (JOIN NOW + LANGUAGE SWITCHER) */}
                            <div className="header-action d-flex align-items-center">
                                {/* Language Switcher Dropdown */}
                                <div
                                    className="language-dropdown position-relative"
                                    style={{ cursor: "pointer", height: "50px", display: "flex" }}
                                    onMouseLeave={() => setLangOpen(false)}
                                >
                                    {/* Toggle Button */}
                                    <div
                                        className="lang-toggle d-flex align-items-center"
                                        onClick={toggleLangMenu}
                                    >
                                        {/* Flag for current locale */}
                                        <Image
                                            src={`/images/flags/${locale.toUpperCase()}.svg`}
                                            alt={`${locale} flag`}
                                            width={30}
                                            height={30}
                                            className="me-2"
                                        />
                                        <span className="current-lang text-uppercase me-1">
                                            {locale}
                                        </span>
                                    </div>

                                    {/* Dropdown Menu */}
                                    {langOpen && (
                                        <ul
                                            className="lang-menu list-unstyled p-2"
                                            style={{
                                                position: "absolute",
                                                top: "50px",
                                                right: "0px",
                                                backgroundColor: "rgb(18 26 39 / 90%)",
                                                borderRadius: "4px",
                                                minWidth: "230px",
                                                zIndex: 999,
                                                display: "flex",
                                                boxShadow: "rgba(0, 0, 0, 0.3) 0px 5px 10px -5px",
                                                flexDirection: "column",
                                                flexWrap: "nowrap",
                                                alignContent: "center",
                                                justifyContent: "center",
                                                alignItems: "flex-start",
                                                height: "450px",
                                                overflow: "auto"
                                            }}
                                        >
                                            {locales?.map((lng, index) => {
                                                const isActive = lng === locale;
                                                return (
                                                    <li key={lng} className="mb-1" style={{ marginTop: index === 0 ? '300px' : '0' }}>
                                                        <Link
                                                            href={asPath}
                                                            locale={lng}
                                                            prefetch={false}
                                                            onClick={handleLangSelect}
                                                            className={`d-flex align-items-center px-2 py-1 ${isActive ? "active-lang" : ""
                                                                }`}
                                                            style={{ textDecoration: "none" }}
                                                        >
                                                            <Image
                                                                src={`/images/flags/${lng.toUpperCase()}.svg`}
                                                                alt={`${lng} flag`}
                                                                width={25}
                                                                height={25}
                                                                className="me-2"
                                                            />
                                                            <span>{t(`languageNames.${lng.toLowerCase()}`)}</span>
                                                        </Link>
                                                    </li>
                                                );
                                            })}
                                        </ul>
                                    )}
                                </div>

                                {/* Join Now Button */}
                                <div className="header-btn d-none d-lg-flex ms-3">
                                    <Link
                                        href="https://app.instaswap.com"
                                        className="trk-btn trk-btn--border trk-btn--primary"
                                        target="_blank"
                                    >
                                        <span>{t("menu.joinNow")}</span>
                                    </Link>
                                </div>

                                {/* Mobile Menu Toggle */}
                                <div
                                    className={`header-bar d-lg-none header-bar--style1 ${menu ? "active" : ""
                                        } ms-3`}
                                    onClick={toggleMenu}
                                >
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </header >
        </>
    );
}

export default Header;
