
    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    import { useEffect, useState } from "react";
import Head from "next/head";
import { useRouter } from "next/router"; // IMPORTANT
import AOS from "aos";
import Layout from "@/components/layout/Layout";
import "@/styles/css/bootstrap.min.css";
import "@/styles/css/font.css";
import "aos/dist/aos.css";
import "@/styles/sass/style.scss";
// Utility function to handle boolean attributes
export const booleanToString = (value) => value ? value.toString() : undefined;
function App({ Component, pageProps }) {
    const [dark, setDark] = useState(false);
    const router = useRouter(); // We use this to get the current locale
    useEffect(() => {
        // Initialize AOS
        AOS.init();
        // Simulate loading (preloader)
        // Get theme from localStorage
        if (localStorage.getItem("theme") === "dark") {
            setDark(true);
        }
        else {
            setDark(false);
        }
    }, []);
    useEffect(() => {
        // CLIENT-SIDE: Switch the <html> direction instantly on locale change
        if (router.locale === "ar") {
            document.documentElement.setAttribute("dir", "rtl");
            document.documentElement.setAttribute("lang", "ar");
        }
        else {
            document.documentElement.setAttribute("dir", "ltr");
            document.documentElement.setAttribute("lang", "en");
        }
    }, [router.locale]);
    return (<>
      <Head>
        <title>Instaswap DEX - Cross Chain Swaps with Native Tokens</title>
        <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
      </Head>

      <Layout>
        <Component {...pageProps}/>
      </Layout>
    </>);
}
const __Next_Translate__Page__195eeb7cbc2__ = App;

    export default __appWithI18n(__Next_Translate__Page__195eeb7cbc2__, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: true,
      loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
    });
  